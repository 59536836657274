
.chart{
    width: 350px;
    height: 300px;
}

.multiple-chart-container{
    display: flex;
    width: 100%;
    height: 400px;
    justify-content: center;
}

.multiple-chart-container>.multi-chart{
    width: 100%;
    padding: 10px;
    height: 400px;
}
