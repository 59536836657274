.sidebar {
    position: fixed;
    top: 70px;
    left: 0;
    width: 240px;
    height: 100%;
    animation: sidebar-appear 0.5s forwards;
    transform: translateX(-240px);
    background: #fff;
    padding: 15px 0;
}

@keyframes sidebar-appear {
    0% {
        transform: translateX(-240px);
    }

    100% {
        transform: translateX(0);
    }
}

.sidebar>.sidebar-container {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px 0;
}

.sidebar+.main-content {
    position: relative;
    margin-left: 240px;
}

.main-content {
    margin-top: 70px;
}


.main-container {
    width: 100%;
    position: relative;
    padding: 10px 20px;
    overflow-x: hidden;
    overflow-y: auto;
}

.view-content {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.view-content>div {
    width: 900px;
}

.view-content>.view-regular {
    width: auto;
}

.sidebar>.sidebar-menu {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 10px 60px 0;
}

.sidebar-menu>a {
    padding: 12px;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    border-radius: 0 10px 10px 0;
    transition: 0.3s ease-in;
}

.sidebar-menu>a>svg {
    transition: 0.3s ease-in;
}

.sidebar-menu>a:hover {
    color: #ff3300;
}

.sidebar-menu>a:hover svg {
    fill: #ff3300;
}

.sidebar-menu>.setting-item {
    font-size: 16px;
    font-weight: 600;
    padding: 12px;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    border-radius: 0 10px 10px 0;
}

.setting-item.active-setting {
    padding-bottom: 10px;
}

.sidebar-menu>.setting-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    border-left: 2px solid #f9f9f9;
}

.setting-container>a {
    padding: 12px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    transition: 0.3s ease-in;
}

.setting-container>a>svg {
    transition: 0.3s ease-in;
}

.setting-container>a:hover {
    color: #ff3300;
}

.setting-container>a:hover svg {
    fill: #ff3300;
}


.setting-container>a:first-child {}

.setting-container>a:last-child {
    border-bottom: none;
}

.setting-container>.sidebar-submenu-active {
    color: #ff3300;
}

.setting-container>.sidebar-submenu-active>svg {
    fill: #ff3300;
}

.sidebar-menu>.sidebar-menu-active,
.sidebar-menu-active {
    background: linear-gradient(120deg, #ff8800, #ff3300);
    color: #fff;
}

.sidebar-menu-active svg {
    fill: #fff;
}

.sidebar-menu>a.sidebar-menu-active:hover {
    color: #fff;
}

.sidebar-menu>a.sidebar-menu-active:hover svg {
    fill: #fff;
}


.sidebar-menu>a {
    display: flex;
    align-items: flex-end;
    font-size: 16px;
    font-weight: 600;
}

.sidebar-menu>a>svg,
.sidebar-menu>div>svg {
    font-size: 18px;
    margin-right: 10px;
    transform: translateY(-2px);
}

.sidebar-bottom {
    position: absolute;
    bottom: 70px;
    font-size: 16px;
    font-weight: 600;
    padding: 22px 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    background: #fff;
    transition: 0.3s ease-in;
}

.sidebar-bottom:hover {
    color: #ff3300;
}

.sidebar-bottom>svg {
    margin-right: 10px;
    transition: 0.3s ease-in;
    transform: translateY(-2px);
}

.sidebar-bottom:hover svg {
    fill: #ff3300;
}

.sidebar-modal {
    z-index: 1000;
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .2);
    display: flex;
    justify-content: center;
    align-items: center;
}


@media only screen and (max-width: 400px) {
    .main-container {
        padding: 10px 10px;
    }
}