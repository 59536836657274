.auth{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
}

.auth-images{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: 100vh;
    position: relative;
    background: #f9f9f9;
    padding-top: 5%;
}


.auth-form{
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 800px;
    min-height: 100vh;
    background: #ffffff;
}

.auth-images>img{
    width: 90%;
}

.auth-form>img{
    width: 350px;
}

.auth-form-container{
    width: 380px;
    margin: 30px 0;
    padding: 10px 12px;
    border-radius: 10px;
}

.auth-tab{
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.auth-tab>span{
    width: 100%;
    padding: 10px 0;
    text-align: center;
    cursor: pointer;
    border-bottom: 3px solid transparent;
    font-size: 18px;
    border-radius: 10px;
    font-weight: 600;
}

.auth-tab>.active{
    color: #ff3300;
}


.auth-forgot>div{
    margin: 10px 0;
    cursor: pointer;
}

.auth-forgot>div:hover{
    text-decoration: underline;
}


@media only screen and (max-width: 1300px) {
    .auth{
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
    }

    .auth-images{
        display: none;
    }
    .auth-form{
        background: #fff;
    }
}