/***SCREEN LOADER***/

/* HTML: <div class="loader"></div> */
.screen-loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  color: #FF8B04;
  width: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;
  transform: translateX(-38px);
  animation: l21 0.5s infinite alternate linear;
}

@keyframes l21 {
  50%  {
    box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px;
  }
  100% {
    box-shadow: 19px 0 0 0, 38px 0 0 3px, 57px 0 0 7px;
  }
}

/***END SCREEN LOADER ***/


/***PAGE LOADER***/

.lds-ring {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 42px;
    height: 42px;
    margin: 4px;
    border: 4px solid #ccc;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ccc transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

#loadingPage {
    display: table;
    width: 100%;
    text-align: center;
}

#loadingItemPage {
    display: table-cell;
    width: 80px;
    height: 80px;
    vertical-align: middle;
}


/***END PAGE LOADER***/
