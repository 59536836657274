.count-reservation{
    border-top: 1px dashed #e6e6e6;
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
}

.reservation-count-container{
    display: flex;
    flex-direction: column;
}

.reservation-count-container>span{
    margin: 5px 0;
}


.reservation-btn-validate{
    text-decoration: underline;
    margin: 10px 10px 0 0;
    cursor: pointer;
    color: #FF8B04;
}

.reservation-btn-cancel{
    text-decoration: underline;
    margin: 10px 10px 0 0;
    cursor: pointer;
    color: #666;
}

.grid-legend{
    width: 100%;
}

.legend-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.legend-container>div{
    display: flex;
    align-items: center;
    margin: 10px 0;

}

.legend-libre{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    margin-right: 5px;
    transform: translateY(-2px);
}

.legend-avance{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #FF8B04;
    margin-right: 5px;
    transform: translateY(-2px);
}

.legend-paye{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #33cb00;
    border: 1px solid transparent;
    margin-right: 5px;
    transform: translateY(-2px);
}

.legend-place{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #FF8B04;
    border: 1px solid transparent;
    margin-right: 5px;
    transform: translateY(-2px);
}

.grid-legend{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}