table {
    width: 100%;
    border-collapse: collapse;
}

table thead:hover{
    background: none;
}

.table-ellipsis{
    max-width: 120px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}
table thead{
    border-bottom: 1px dashed #ddd;
}

table th,
table td {
    padding: 12px 10px;
    text-align: left;
}

table tr{
    
}

table tr:nth-child(even){
    background: #f9f9f9;
}

table tr:last-child{
    border-bottom: none;
}

table>thead tr:first-child{
    cursor: default;
}

table th {
    font-weight: bold;
}

table>tbody tr {
    cursor: pointer;
}

table>tbody tr:hover {
    background-color: #f2f2f2;
}

table .selected:nth-child(even) {
    background: #f2f2f2;
}