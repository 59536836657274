.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: #fff;
    border-bottom: 1px solid #e6e6e6;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    z-index: 1000;
}

.navbar-container {
    width: 100%;
    height: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar-menu {
    display: flex;
    align-items: center;
}

.navbar-menu>.navbar-menu-item {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    position: relative;
    cursor: pointer;
    background: #f9f9f9;
}

.navbar-menu>.navbar-menu-item-active {
    background: linear-gradient(120deg, #ff8800, #ff3300);
}

.navbar-menu>.navbar-menu-item-active svg {
    fill: #fff;
}

.navbar-menu>.navbar-menu-item:last-child {
    margin-right: 20px;
}

.navbar-menu-item>span {
    padding: 1px 6px;
    border-radius: 15px;
    border: 3px solid #fff;
    background: red;
    color: #fff;
    position: absolute;
    top: 2px;
    left: 58%;
    font-size: 12px;
    font-weight: 800;
}

.navbar-logo {
    display: flex;
    align-items: center;
}

.navbar-logo>img {
    width: 15%;
    cursor: pointer;
}

.navbar-logo>svg {
    font-size: 34px;
    margin-left: 15px;
    fill: #333;
    cursor: pointer;
    padding: 3px;
    border-radius: 3px;
    background: #fff;
    border: 1px solid #333;
}

.navbar-user {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 10px;
}

.navbar-user>span {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f3f3b9;
    font-weight: 800;
    border-radius: 50%;
}



.navbar-user-info {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.navbar-user-info>.email {
    font-size: 12px;
    color: #7b7b7b;
    max-width: 150px;
}

.navbar-user>svg {
    margin-left: 10px;
    cursor: pointer;
}

.navbar-dropdown-container {
    background: #fff;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px 5px;
    position: fixed;
    top: 80px;
    right: 10px;
    width: 350px;
    max-height: 80%;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
}

.navbar-dropdown-container>.navbar-dropdown-title {
    padding: 10px 5px;
    border-bottom: 1px dashed #f9f9f9;
}

.navbar-dropdown-container>.navbar-dropdown-item {
    padding: 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f9f9f9;
    cursor: pointer;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
}

.navbar-dropdown-container>.navbar-dropdown-item:last-child {
    border-bottom: none;
}

.navbar-dropdown-container>.navbar-dropdown-item:hover {
    background: #f9f9f9;
}

.navbar-dropdown-item>svg {
    transform: translateY(-2px);
}

.navbar-dropdown-item>svg {
    margin-right: 10px;
}

/*** NOTIFICATION ***/

.navbar-dropdownlist-header {
    padding: 10px 8px;
}

.navbar-dropdownlist-header>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar-dropdownlist-header>div>h3 {
    margin: 0;
}

.navbar-dropdownlist-header>div>svg {
    cursor: pointer;
}

.navbar-dropdownlist-tabs {
    display: flex;
    align-items: center;
    padding: 0 8px;
    margin-bottom: 10px;
}

.navbar-dropdownlist-tabs>span {
    padding: 8px 12px;
    border-radius: 15px;
    font-weight: 600;
    margin-right: 5px;
    cursor: pointer;
    border: 1px solid transparent;
}

.navbar-dropdownlist-tabs>span:hover {
    background: #f9f9f9;
}

.navbar-dropdownlist-tabs>.navbar-dropdownlist-active {
    background: #f9f9f9;
    border: 1px solid #e9e9e9;
}



.navbar-dropdownlist-item {
    padding: 10px 5px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    line-height: 1.8;
    display: flex;
    align-items: center;
}

.navbar-dropdownlist-item:hover {
    background: #f9f9f9;
}

.navbar-dropdownlist-item>.img-container {
    min-width: 50px;
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 50%;
    padding: 3px;
    background: #f9f9f9;
}

.navbar-dropdownlist-item>.img-container>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #fff;
}

.navbar-dropdownlist-item>.detail-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.navbar-dropdownlist-item>.detail-container>h4 {
    font-size: 13px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}

.navbar-dropdownlist-item>.detail-container>p {
    font-size: 13px;
    margin: 0;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}

.navbar-dropdownlist-item>.detail-container>span {
    margin: 0;
    width: 100%;
    font-size: 11px;
    display: flex;
    justify-content: flex-start;
}

.unread {
    font-weight: 700;
}

.navbar-dropdown-bottom {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-dropdown-bottom>span {
    cursor: pointer;
    font-weight: 700;
    transition: 0.5s ease-in-out;
}

.navbar-dropdown-bottom>span:hover {
    color: #ff3300;
}