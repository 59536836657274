@media print {
    @page {
        margin: 0cm;
        size: landscape;
    }

    .reservation-ticket {
        display: block !important;
        height: 100%;
        width: 100%;
        page-break-after: always;
        padding: 10px 20px;
        font-size: 14px;
    }
    .ticket-title{
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        opacity: 0.5;
    }

    .ticket-title>div{
        width: 100%;
        margin-left: 10px;
        border-bottom: 1px dashed #ddd;
    }

    .ticket-title>h4{
        margin: 0;
        padding: 0;
        font-size: 22px;
        text-wrap: nowrap;
    }

    .ticket-detail> h4,p{
        font-size: 18px;
        margin: 10px 0;
    }

    .ticket-detail>p>span{
        font-size: 18px;
    }


    .seat {
        width: 25px;
        height: 25px;
        border: 1px solid transparent;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 5px;
    }

    .seat-selected{    
        width: 25px;
        height: 25px;    
        border: 1px solid #ccc;
    }
}

.print-container{
    display: none;
}

/*** CARSEAT ***/

.print-carseat-container{
    display: flex;
    margin: 20px 0;
    justify-content: flex-start;
}

.print-carseat {
    width: auto;
    border: 2px solid #0000009a;
    padding: 10px 15px;
    border-radius: 10px ;
    background: transparent;
}

.print-car-row {
    display: flex;
}

.print-driver-seat{
    width: calc(100% - 127px);
    height: 40px; 
    background: #fff;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    border-radius: 3px;
    font-size: 18px;
}


.print-seat {
    width: 50px;
    height: 40px;
    background: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 5px;
    border-radius: 3px;
    font-size: 13px;
    font-weight: 800;
}

.print-seat-selected{
    border: 1px solid #000;
    color: #000;
}

.print-seat-avanced{
    border: 1px solid #000;
    color: #000;
}

.print-seat-payed{
    border: 1px solid #000;
    color: #000;
}