.modal-form {
    z-index: 1500;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .2);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.modal-form>div{
    padding: 12px;
    min-width: 320px;
    max-width: 600px;
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #fff;
    border-radius: 5px;
}

.modal-form>div>form{
    max-height: 450px;
    overflow-y: auto;
    padding: 0 10px;
}

.form-large{
    width: 50%;
}

.form-modal-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.form-modal-header>svg{
    font-size: 22px;
    cursor: pointer;
}

.form-modal-footer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}