.rate-star{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start ;
    margin-bottom: 12px;
    border-bottom: 1px dashed #ddd;
}

.rate-star>span{
    opacity: 0.8;
}

.action-note{
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.action-note:hover{
   opacity: 0.5;
}

.action-note>span{
    cursor: pointer;
    opacity: 0.8;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.action-note>span>svg{
    margin-right: 5px;
}