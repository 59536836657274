/*** SIZE ***/
.small {
    width: auto;
}

.regular {
    width: 300px;
}

.input-regular {
    width: 400px;
}

.medium {
    width: 610px;
}

.large {
    width: 100%;
}

/*** END SIZE ***/

.input-container {
    display: block;
    margin: 10px 0;
}

.input-container>label {
    font-size: inherit;
    text-wrap: nowrap;
}

.input-container>div {
    display: flex;
    align-items: center;
    background: #f9f9f9;
    border: 1px solid #eaeaea;
    padding: 5px 12px;
    margin-top: 10px;
    border-radius: 5px;
}

.input-container>.input-select {
    position: relative;
    user-select: none;
}

.input-container>.input-select>.select-placeholder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    color: #fff;
}

.input-container>.input-select>.select-content {
    position: absolute;
    top: 105%;
    left: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0 2px 2px rgb(0 0 0 / 10%);
    z-index: 1;
    max-height: 150px;
    overflow-y: auto;
    padding: 5px 0;
    border-radius: 10px;
}

.input-container>.input-select>.select-content::-webkit-scrollbar {
    /*display: none;*/
}

.select-content>.select-option {
    padding: 13px 16px;
    cursor: pointer;
    transition: all 0.2s;
    text-transform: capitalize;
}

.select-content>.select-option:hover {
    background: #e2e2e2;
}

.input-icon {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.input-icon>svg {
    fill: #333;
}

.input-date,
.input-container>div>input,
.input-container>div>textarea,
.input-container>div>select,
.input-container>div>.select-placeholder {
    padding: 8px 0;
    border: none;
    outline: none;
    width: 100%;
    background: none;
    resize: none;
    font-size: 14px;
}

.input-date::placeholder,
.input-container>div>input::placeholder,
.input-container>div>textarea::placeholder,
.input-container>div>select::placeholder {
    color: #ababab;
}

/***CHECKBOX***/
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 16px;
    color: #333;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
}

.checkbox-container:hover input~.checkmark {
    background-color: #ccc;
}

.checkbox-container input:checked~.checkmark {
    background-color: #FF8B04;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked~.checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    left: 5px;
    top: 4px;
    width: 2px;
    height: 5px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkbox-form-container {
    padding: 10px;
    border: solid 1px #ddd;
    display: flex;
    flex-wrap: wrap;
}

.checkbox-card {
    margin: 5px;
    padding: 5px;
    width: calc(50% - 10px);
    min-width: calc(50% - 10px);
}

/***INPUT FILE***/
input[type="file"] {
    display: none;
    width: 100%;
}

.input-file-container {
    display: flex;
    flex-direction: column;
}

.input-file>label {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
    border: 3px dashed #ddd;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.input-file>label>svg {
    fill: #ddd;
}

.input-file .label-img.dragging {
    border-color: #999;
    background-color: #f9f9f9;
}

.input-file.dragging .label-img svg {
    fill: #999;
}

.input-file-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.input-file-list>div>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.input-file-list>div>span {
    color: #333;
    padding: 10px 12px;
    background: #f9f9f9;
    border-radius: 10px;
}

.input-file-list-view {
    position: relative;
    margin: 10px 10px 0 0;
    padding: 3px;
    background: #f9f9f9;
    width: 100px;
    height: 100px;
    border-radius: 5px;
}

.input-file-list-view>svg {
    background: #fff;
    padding: 3px;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: -10px;
    right: -10px;
}

.input-file-list-view>svg:hover {
    background: #f9f9f9;
}


/*** INPUT RADIO ***/
.custom-radio-group {
    border: 1px dashed #ccc;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    width: auto;
    margin: 10px;
    min-width: 200px;
}

.custom-radio-container {
    display: flex;
    margin: 10px 15px;
}

.custom-radio-label {
    cursor: pointer;
    text-wrap: nowrap;
}

.radio-label {
    padding: 0 10px;
    margin-left: 10px;
}

.custom-radio-input {
    margin-right: 10px;
}

/*** INPUT SEND WITH EMOJI ***/
.input-send-container {
    display: flex;
    align-items: flex-end;
    flex-wrap: nowrap;
    width: 100%;
    border: 1px solid #ddd;
    background: #f9f9f9;
    border-radius: 10px;
    padding: 10px;
    position: relative;
}

.input-send-container>textarea {
    width: 100%;
    border: none;
    outline: none;
    background: none;
    resize: none;
    padding: 5px;
}

.input-send-container>.input-send-btn-container {
    padding: 0 5px;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
}

.input-send-container>.input-send-btn-container>span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
}

.input-send-btn-container>span>svg {
    fill: #ddd;

}

.input-send-btn-container>span svg {
    transition: 0.5s ease-in-out;
}

.input-send-btn-container>span:hover svg {
    fill: #ff3300;
}

.input-send-container>.emoji-container {
    position: absolute;
    right: 0;
    width: auto;
    height: auto;
    background: #fff;
    z-index: 1000;
}

.input-send-container>.emoji-container.emoji-container-bottom {
    top: 101%;
}

.input-send-container>.emoji-container.emoji-container-top {
    bottom: 101%;
}

.input-send-btn-container>span>svg.input-send-btn-active {
    fill: #ff3300;
}

/*** INPUT USER MUTLIPLE ***/
.selected-users {
    background: #f9f9f9;
    border: 1px solid #eaeaea;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 8px 12px;
    border-radius: 5px;
    margin-top: 10px;
}

.selected-users>.selected-user {
    display: flex;
    align-items: center;
    border: 3px;
    padding: 5px 3px;
    background: #fff;
    width: auto;
    margin-right: 5px;
    margin-bottom: 5px;
}

.selected-users>.selected-user>svg {
    cursor: pointer;
}

.selected-users>svg {
    cursor: pointer;
}

/*** LIST MODAL WITH IMG ***/
.list-modal-with-img {
    display: flex;
    align-items: center;
}

.list-modal-with-img>.img-container {
    width: 80px;
    min-width: 80px;
    height: 80px;
    object-fit: cover;
    padding: 5px;
    border-radius: 50%;
    background: #f9f9f9;
    margin-bottom: 10px;
    margin-right: 10px;
}

.list-modal-with-img>.img-container>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #fff;
}

.list-modal-with-img>.list-modal-details {
    display: block;
}

.list-modal-with-img>.list-modal-details>h3 {
    margin: 0;
}