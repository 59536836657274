.home {
    display: block;
}

.home-chart-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.home-chart-container>.left-info,
.home-chart-container>.right-info {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 10px;
    padding: 15px;
    height: 400px;
    margin: 10px 0;
}

.home-chart-container>.left-info {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 420px);
}

.home-chart-container>.right-info {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.left-info>.card-voyage-reservation {
    display: flex;
    width: 100%;
}

.card-voyage-reservation>.chart-voyage-reservation-container {
    width: 100%;
    height: calc(400px - 30px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 10px;
}

.chart-voyage-reservation-container>.multichart-voyage-reservation {
    width: 100%;
    height: calc(100% - 70px);
    overflow: hidden;
}

.data-count-voyage-reservation {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.data-count-voyage-reservation>.block>h3 {
    margin: 0;
    font-size: 22px;
    text-wrap: nowrap;
}

.data-count-voyage-reservation>.block {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.data-count-voyage-reservation>.block>h1 {
    margin: 0;
    font-size: 40px;
}

.data-count-voyage-reservation>.block>span {
    text-wrap: nowrap;
    color: #d4d4d4;
}

.multichart-tabs-filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}

.multichart-tabs-filter>.multichart-tabs-left {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.multichart-tabs-filter>.multichart-tabs-left>span {
    margin: 5px 3px;
    border-bottom: 2px solid transparent;
    padding: 5px 10px;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    color: #d4d4d4;
}

.multichart-tabs-filter>.multichart-tabs-left>.active-filter {
    background: #f9f9f9;
    color: #333;
}

.right-info>h3 {
    margin: 0;
}

.right-info>.doughnut-chart {
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.right-info>.doughnut-chart-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.doughnut-chart-info>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.doughnut-chart-info>div>h4 {
    margin: 0;
    font-size: 25px;
}

.doughnut-chart-info>div>span {
    margin-top: 0;
    font-size: 11px;
    text-transform: capitalize;
}

@media only screen and (max-width: 1000px) {

    .home-chart-container>.left-info,
    .home-chart-container>.right-info {
        width: 100%;
        height: auto;
    }

    .card-voyage-reservation {
        display: flex;
        flex-direction: column;
    }

    .data-count-voyage-reservation {
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
}



@media only screen and (max-width: 500px) {
    .data-count-voyage-reservation {
        flex-direction: column;
        justify-content: center;
    }
}

/*** END HEADER ***/

.home-grid-container {
    margin: 10px 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

.home-grid-container>.home-grid-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    padding: 10px 20px;
    border-radius: 5px;
}

.home-grid-container>.home-grid-card>h4 {
    margin: 0;
    font-size: 14px;
    margin-bottom: 10px;
}

.home-grid-card>.grid-detail {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.home-grid-card>.grid-detail>h1 {
    margin: 0;
    font-size: 40px;
    color: #ff3300;
}

.home-grid-card>.grid-detail>svg {
    fill: #d4d4d4;
}

@media only screen and (max-width: 900px) {
    .home-grid-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 500px) {
    .home-grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
}

/*** HOME PASSAGER ***/
.rbc-event-content{
    color: #fff;
}

.tooltip {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    padding: 10px 15px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    z-index: 1000;
    display: none;
}

.tooltip>h3{
    margin: 0;
}

.tooltip .close-tooltip {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

/*** HOME ADMIN ***/
.home-filter{
    width: 50%;
}
@media only screen and (max-width: 800px) {
    .home-filter{
        width: 70%;
    }
}

@media only screen and (max-width: 660px) {
    .home-filter{
        flex-direction: column;
        width: 100%;
    }
}

.home-admin-card{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;
}

.doughnut-container{
    display: block;
    width: 200px;
    height: 200px;
}

.doughnut-container>.doughnut-chart{
    width: 100%;
    height: 100%;
}

.doughnut-container>.doughnut-chart-info{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding-bottom: 10px;
}

@media only screen and (max-width: 800px) {
    .doughnut-container{
        margin-bottom: 100px;
    }
    
}


.home-total-paiement{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}

.home-total-paiement>h1{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff3300;
    margin: 0;
    font-size: 35px;
}

/*** HOME ADMIN SITE ***/
.home-adminsite-chart{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.line-chart{
    display: flex;
    align-items: center;    
}

.line-chart>div{
    width: 50%;
    height: 300px;
    padding: 0 10px;
    display: block;
    margin-bottom: 60px ;
}

@media only screen and (max-width: 900px) {
    .line-chart{
        flex-direction: column;
    }
    
    .line-chart>div{
        width: 100%;
    }
}

.home-paiement{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 100px;
}

.home-adminsite-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(50px);
}

.home-adminsite-info>.home-adminsite-details{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home-adminsite-info>.home-adminsite-details>h1{
    text-align: center;
    color: #ff3300;
    margin: 0;
    font-size: 35px;
}

@media only screen and (max-width: 800px) {
    .home-paiement{
        margin-bottom: 20px;
    }
    .home-adminsite-info{
        transform: translateY(0);
    }
}

@media only screen and (max-width: 600px) {
    .home-paiement{
        flex-direction: column;
    }
}