.view-container {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    justify-content: space-between;
}

.view-details {
    /*width: 500px;*/
    height: calc(100% - 60px);
    position: fixed;
    top: 70px;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.view-background {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.view-background>img {
    width: 70%;
}

.view-list-header {
    margin-bottom: 15px;
}

.view-list {
    position: relative;
    padding: 0 10px;
}

.view-card-container {
    margin-bottom: 10px;
    padding: 10px 15px;
    border: 1px solid #f9f9f9;
    border-radius: 10px;
    cursor: pointer;
}

.selected {
    background: #f2f2f2;
}

.view-show-container {
    padding: 0 15px;
}

.show-header-container {
    border-bottom: 1px solid #e6e6e6;
}


.view-show {
    padding: 20px 0;
}

.view-show>p {
    line-height: 1.5;
}

.action-container {
    display: flex;
    flex-wrap: wrap;
}

.action-container>span {
    margin: 10px 10px 0 0;
    text-decoration: underline;
    cursor: pointer;
    color: #666;
}

.action-container>span:hover {
    color: #333;
}

/***TAB***/
.tab-view-container {
    padding: 10px 0;
}

.tab-view-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
}

.tab-view-list>div {
    color: #666;
    cursor: pointer;
    margin: 0 10px 10px 0;
    padding: 10px 12px;
    border: 1px solid transparent;
    background: #f9f9f9;
    border-radius: 5px 5px 0 0;
}

.tab-view-list>.active {
    border: 1px solid #999;
    border-bottom: none;
}

td>.img-container {
    width: 100px;
    height: 100px;
    padding: 5px;
    background: #f9f9f9;
    border-radius: 50%;
    object-fit: cover;
}

td>.img-container>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

@media only screen and (max-width: 1280px) {
    .view-list {
        padding: 0;
    }
}

/*** CARD WITH IMG ***/

.view-card-space-between {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    margin: 10px 0;
}

.view-card-with-img {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 450px) {
    .view-card-with-img {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .view-card-with-img>.card-with-img-detail {
        margin-top: 5px;
    }

    .view-card-with-img>.card-with-img-detail>.badge,
    .view-card-with-img>.card-with-img-detail>h2,
    .view-card-with-img>.card-with-img-detail>p {
        text-align: center;
        justify-content: center;
    }
}

.view-card-with-img>.img-container {
    width: 100px;
    min-width: 100px;
    height: 100px;
    min-width: 100px;
    border-radius: 5px;
    object-fit: cover;
    padding: 5px;
    background: #f9f9f9;
    border-radius: 50%;
}

.view-card-with-img>.img-container>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #fff;
}

.view-card-with-img>.card-with-img-detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
}

.card-with-img-detail>h2 {
    margin: 0;
    font-size: 20px;
    line-height: 1.2;
}

.card-with-img-detail>p {
    display: flex;
    align-items: center;
    opacity: 0.7;
}

.card-with-img-detail>p>svg {
    fill: #e4e4e4;
    margin-right: 5px;
}

.view-card-action {
    margin-top: 15px;
    color: #ff3300;
    cursor: pointer;
}

.view-card-timing {
    display: flex;
    align-items: center;
    opacity: 0.7;
}

.view-card-timing>svg {
    color: #e4e4e4;
    margin-right: 5px;
    transform: translateY(-2px);
}

/*** SHOW VIEW ***/
.view-show>.show-container-with-img {
    width: 100%;
    display: flex;
    align-items: center;
}

.show-container-with-img>.show-img-container {
    width: 100px;
    min-width: 100px;
    height: 100px;
    object-fit: cover;
    padding: 5px;
    border-radius: 50%;
    background: #f9f9f9;
    margin-bottom: 10px;
}

.show-container-with-img>.show-img-container>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #fff;
}

.show-container-with-img>.show-img-details {
    margin-left: 10px;
}

.show-container-with-img>.show-img-details>h3 {
    margin: 0;
    text-transform: uppercase;
}

.show-container-with-img>.show-img-details>span {
    opacity: 0.8;
}

.show-container-with-img>.show-img-details>p {
    margin-top: 10px;
}

.view-show>.important, .important {
    color: #ff3300;
    font-weight: 700;
}

.tabs-btn {
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
}

.tabs-btn>svg {
    margin-left: 5px;
}

/*** ***/
.view-print-badge{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 0;
}

.view-print-badge>.view-print-badge-button{
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.5s ease-in-out;
}

.view-print-badge>.view-print-badge-button>svg{
    transition: 0.5s ease-in-out;
}

.view-print-badge>.view-print-badge-button>span{
    transition: 0.5s ease-in-out;
}

.view-print-badge>.view-print-badge-button>svg{
    transform: translateY(-2px);
    margin-right: 5px;
}

.view-print-badge:hover .view-print-badge-button span {
    color: #ff3300;
}

.view-print-badge:hover .view-print-badge-button svg {
    fill: #ff3300;
}
