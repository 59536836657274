.profile {
    display: flex;
    align-items: flex-start;
}

.profile>.profile-detail-container {
    width: 350px;
    display: block;
}

.profile>.profile-post-container {
    width: calc(100% - 350px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-biographie {
    text-align: justify;
    line-height: 1.5;
    margin: 10px 0;
    opacity: 0.8;
    white-space: pre-line;
}

.profile-img-container {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50%;
    padding: 5px;
    background: #f9f9f9;
    object-fit: cover;
}

.profile-img-container>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.text-details-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
}

.btn-showdetail {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;
}

.btn-showdetail:hover {
    opacity: 0.8;
}

.btn-showdetail>svg {
    margin-left: 5px;
}

.text-details-header>h1 {
    width: 100%;
    text-align: center;
    margin: 0;
    line-height: 1.3;
}

.text-details-header>h1>span {
    font-size: 20px;
}

.text-details-header>span {
    display: flex;
    align-items: center;
    color: #ff3300;
    cursor: pointer;
}

.text-details-header>span>svg {
    fill: #ff3300;
    margin-right: 5px;
    transform: translateY(-2px);
}

.profile-details {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.profile-details>div {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 3px 0;
}

.profile-details>div>.text-label {
    text-wrap: nowrap;
}

.profile-details>div>.text-detail {
    text-align: right;
    max-width: 60%;
    opacity: 0.8;
}

/*** PROFILE BLOG ***/
.blogprofile-list-container {
    padding: 10px 0;
    display: block;
}

.blogprofile-card {
    display: flex;
    flex-direction: column;
    width: 500px;
    border: 1px solid #f9f9f9;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 10px;
}

.blogprofile-card>.img-container {
    width: 100%;
    min-width: 100%;
    height: 250px;
    object-fit: contain;
    border-radius: 50%;
    padding: 3px;
    background: #f9f9f9;
}

.blogprofile-card>.img-container>img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
    border: 3px solid #fff;
}

.blogprofile-card>.card-blogprofile-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 10px;
}

.blogprofil-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.blogprofil-header>h3 {
    margin: 0;
    max-width: 70%;
}

.blogprofil-header>svg {
    cursor: pointer;
}

.blogprofile-detail {
    width: 100%;
}

.blogprofile-detail>p {
    opacity: 0.8;
    text-align: justify;
    line-height: 1.5;
    white-space: pre-line;
}

.blogprofile-detail>.blog-all-text {
    line-height: 1.5;
    white-space: pre-line;
}

.blogprofile-detail>.blog-all-text {
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    max-height: 3em;
}

.blogprofile-action {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px 0;
}

.blogprofile-action>.action-btn {
    cursor: pointer;
}

.blogprofile-action>.action-btn:hover {
    opacity: 0.8;
}

.blogprofile-action>.action-btn>svg {
    transform: translateY(3px);
    margin-right: 5px;
}

/*** POST ***/
.blogprofile-post{
    display: flex;
    flex-direction: column;
    width: 500px;
}

.blogprofile-post>.post-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}

.blogprofile-post>.post-header>span{
    display: flex;
    flex-wrap: nowrap;
    text-wrap: nowrap;
    align-items: center;
    cursor: pointer;
}

.blogprofile-post>.post-header>span:hover{
    opacity: 0.8;
}

.post-header>span>svg{
    margin-right: 5px;
}

.post-header>svg{
    cursor: pointer;
}

.post-header>svg:hover{
    opacity: 0.8;
}

/*** INPUT ***/
.blogprofile-post>.input-blog-filter {
    width: 100%;
    min-width: 300px;
    background: #f9f9f9;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 12px 8px;
    border-radius: 5px;
    position: relative;
}

.blogprofile-post>.input-blog-filter>span {
    width: 30px;
    display: flex;
    justify-content: center;
}

.blogprofile-post>.input-blog-filter>span>svg {
    color: #ddd;
    transform: translateY(-1px);
    opacity: 0.5;
}


.blogprofile-post>.input-blog-filter>input {
    width: calc(100% - 50px);
    border: none;
    outline: none;
    padding: 0 8px;
    background: none;
    transform: translateY(1px);
    font-size: 16px;
}

.blogprofile-post>.input-blog-filter>svg {
    position: absolute;
    top: 25%;
    right: 8px;
    cursor: pointer;
}


@media only screen and (max-width: 900px) {
    .profile {
        flex-direction: column;
    }

    .profile>.profile-detail-container {
        width: 100%;
    }

    .profile>.profile-post-container {
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .blogprofile-card {
        width: 100%;
    }

    .blogprofile-post{
        width: 100%;
    }    
}

@media only screen and (max-width: 360px) {
    .blogprofile-action>.action-btn>span {
        display: none;
    }
}