.conversation-card {
    padding: 10px 5px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    line-height: 1.8;
    display: flex;
    align-items: center;
    width: auto;
}

.conversation-card>.img-container {
    min-width: 50px;
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 50%;
    padding: 3px;
    background: #f9f9f9;
}

.conversation-card>.img-container>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #fff;
}

.conversation-card>.detail-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.conversation-card>.detail-container>h4 {
    font-size: 13px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}

.conversation-card>.detail-container>p {
    font-size: 13px;
    margin: 0;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}

.conversation-card>.detail-container>span {
    margin: 0;
    width: 100%;
    font-size: 11px;
    display: flex;
    justify-content: flex-start;
}

/*** SHOW CONVERSATION ****/

.show-conversation {
    width: 100%;
    height: calc(100vh - 70px);
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    border-left: 1px solid #f9f9f9;
}

.show-conversation-header {
    position: absolute;
    top: 0;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid #f9f9f9;
}

.show-conversation-header>h4 {
    width: 50%;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}

.show-conversation-liste {
    margin-top: 60px;
    width: 100%;
    height: calc(100vh - 240px);
    overflow-x: hidden;
    overflow-y: auto;
    background: #fff;
    padding: 10px;
    display: flex;
    flex-direction: column-reverse;
}

@media only screen and (max-width: 450px) {
    .show-conversation {
        width: 100%;
        height: calc(100vh - 120px);
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        border-left: 1px solid #f9f9f9;
    }

    .show-conversation-liste {
        height: calc(100vh - 290px);
    }
}

.show-conversation-liste>div>div {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
}

/*********************************/

.show-conversation-container {
    margin: 1px;
    width: auto;
}

.show-conversation-container.conversation-show-me {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.show-conversation-container.conversation-show-other {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.show-conversation-container>.show-conversation-card {
    display: flex;
    flex-direction: column;
}

.show-conversation-container>.show-conversation-card.conversation-card-me {
    align-items: flex-end;
}

.show-conversation-container>.show-conversation-card.conversation-card-other {
    align-items: flex-start;
}

.show-conversation-container>.show-conversation-card>.conversation-message {
    display: flex;
    align-items: center;
}

.show-conversation-card>.conversation-message>.img-container {
    min-width: 40px;
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 50%;
    padding: 1px;
    background: #f9f9f9;
}

.conversation-message>.img-container>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #fff;
}

.show-conversation-card>.conversation-message>p {
    margin-left: 5px;
    padding: 10px;
    border-radius: 15px;
    text-align: justify;
    white-space: pre-line;
}

.show-conversation-card>.conversation-message>p.msg-me {
    background: #ff3300;
    color: #fff;
}

.show-conversation-card>.conversation-message>p.msg-other {
    background: #f9f9f9;
}

.show-conversation-card>span.msg-date-me {
    display: flex;
    justify-content: flex-end;
}

.show-conversation-card>span.msg-date-other {
    display: flex;
    justify-content: flex-start;
}

/*********************/
.show-message-footer {
    background: #fff;
    padding: 10px;
}