.badge-outline {
    padding: 5px 8px;
    border-radius: 8px;
    text-transform: lowercase;
}

.badge-red {
    color: #ff4646;
    background: #ff46461c;
}

.badge-green {
    color: #09ff00;
    background: #09ff001a;
}

.badge-purple {
    color: #9c27b0;
    background: #9b27b01c;
}

.badge-orange {
    color: #ff5e1f;
    background: #ff5f1f1f;
}

.badge-yellow {
    color: #fff700;
    background: #fff70021;
}


.badge-pink {
    color: #ff0066;
    background: #ff006623;
}

.badge-blue {
    color: #0400ff;
    background: #0400ff27;
}

.badge-round {
    width: 8px;
    height: 8px;
    border-radius: 25px;
    border: 3px solid #666;
}

.badge-round-green {
    border: 3px solid #09ff00;
}

.badge-round-red {
    border: 3px solid #ff4646
}

.badge-round-orange {
    border: 3px solid #ff5e1f;
}

.badge-round-purple {
    border: 3px solid #9c27b0;
}

.badge {
    display: flex;
    align-items: center;
    text-transform: capitalize;
}

.badge-shape {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    transform: translateY(-3px);
}