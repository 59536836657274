.input-modal {
    z-index: 2000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .2);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.input-modal>div {
    padding: 12px;
    width: 500px;
    max-height: 650px;
    background-color: #fff;
    border-radius: 5px;
}

.input-modal>div>form {
    max-height: 500px;
    overflow-y: auto;
}


.input-modal>div>form,
.input-modal>div>div {
    width: 100%;
}

.list-modal-container {
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
}

.list-modal {
    display: block;
    border-top: 1px solid #e6e6e6;
    padding: 10px;
    cursor: pointer;
}

.list-modal:hover {
    background: #f9f9f9;
}

.list-modal>h3 {
    margin: 0;
    text-transform: capitalize;
}

.list-modal>span {
    background: none;
}