.story-container {
    border-top: 1px solid #ccc;
    padding: 10px 10px;
}

.story-container>h4{
    color: #666;
}

.story-note {
    color: #777;
    font-style: italic;
    padding-bottom: 10px;
    white-space: pre-line;
}

.trace, .trace>span {
    color: #666;
    font-family: CallingCode!important;
    white-space: pre-line;
}

.story-footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.story-footer>span{
    color: #777;
}

/*** VOYAGE COMMENTAIRE ***/
.note-commentaire-container {
    border-top: 1px solid #ccc;
    padding: 10px 10px;
}

.note-commentaire-header{
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.5s ease-in-out;
}

.note-commentaire-header:hover{
    opacity: 0.8;
}

.note-commentaire-header>.img-container {
    width: 40px;
    height: 40px;
    padding: 5px;
    background: #f9f9f9;
    border-radius: 50%;
    object-fit: cover;
}

.note-commentaire-header>.img-container>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.note-commentaire-header>span{
    margin-left: 5px;
}

.note-commentaire {
    color: #777;
    font-style: italic;
    padding-bottom: 10px;
    white-space: pre-line;
}

.note-commentaire-footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}