.bg-red {
    color: #fff;
    background: #ff4646
}

.bg-green {
    color: #fff;
    background: #09ff00;
}

.bg-purple {
    color: #fff;
    background: #9c27b0;
}

.bg-orange {
    color: #fff;
    background: #ff5e1f;
}

.bg-yellow {
    color: #fff;
    background: #fff700;
}


.bg-pink {
    color: #fff;
    background: #ff0066;
}

.bg-blue {
    color: #fff;
    background: #0400ff;
}

/*** COLOR ***/

.cl-red {
    color: #ff4646
}

.cl-green {
    color: #09ff00;
}

.cl-purple {
    color: #9c27b0;
}

.cl-orange {
    color: #ff5e1f;
}

.cl-yellow {
    color: #fff700;
}


.cl-pink {
    color: #ff0066;
}

.cl-blue {
    color: #ff0066;
}