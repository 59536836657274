.btn {
    padding: 10px 15px;
    border: 1px solid transparent;
    outline: none;
    background: none;
    border-radius: 5px;
    margin: 10px 0;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
}

.btn[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn-primary {
    background: linear-gradient(120deg, #ff8800, #ff3300);
    color: #fff;
}

.btn-secondary {
    background: #878B98;
    color: #fff;
}

.btn-outline {
    border: 1px solid #e6e6e6;
    background: none;
}

.form-btn{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.btn-margin{
    margin-right: 10px;
}

.btn-group{
    display: flex;
    align-items: center;
}