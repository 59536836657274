.modal {
    z-index: 2000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .2);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.badge-modal{
    z-index: 2000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .2);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.badge-modal>div{
    padding: 12px 15px;
    min-width: 320px;
    max-width: 820px;
    width: 820px;
    max-height: 80vh;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 5px;
}

.modal>.toast-container {
    background: none;
}

.modal>div{
    padding: 12px 15px;
    min-width: 320px;
    max-width: 600px;
    max-height: 600px;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 5px;
}

.confirm-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1px solid #f9f9f9;
}

.confirm-header>svg {
    font-size: 22px;
    cursor: pointer;
}

.confirm-body {
    padding: 3px 0;
    max-width: 450px;
}

.confirm-body>p {
    line-height: 1.2;
    text-align: justify;
    margin: 20px 0;
    font-size: 16px;
}

.confirm-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.confirm-footer>.btn-cancel {
    padding: 10px 12px;
    border-radius: 25px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: 0.5s ease;
}

.confirm-footer>.btn-cancel:hover {
    color: #FF8B04;
}

.confirm-footer>.btn-ok {
    padding: 10px 12px;
    background: linear-gradient(120deg, #ff8800, #ff3300);
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    border: 1px solid transparent;
}
