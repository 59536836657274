.map-view-container{
    width: 100%;
    height: 88vh;
}


.map-container{
    width: 100%;
    height: 80vh;
}

.map-title{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.map-title>svg{
    margin-right: 10px;
    cursor: pointer;
}

.marker-label{
    background: #fff;
    padding: 2px;
    transform: translateY(-25px);
    max-width: 150px; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}