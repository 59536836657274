.voyage-client-header{
    margin-bottom: 20px;
}

.voyage-client-header>.input-group{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.voyage-client-header>.input-group>div:first-child{
    margin-left: 0;
}

.voyage-client-header>.input-group>div{
    margin: 0 10px;
}

.voyage-client-header>.input-group>div:last-child{
    margin-right: 0;
}


@media only screen and (max-width: 650px) {
    .voyage-client-header>.input-group{
        display: flex;
        flex-direction: column;
    }

    .voyage-client-header>.input-group>div{
        margin: 10px 0;
    }
}

/**** VOYAGE CLIENT ****/

.voyage-client-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;    
}

.voyage-filter{
    width: 250px;
}

.voyage-data{
    width: calc(100% - 300px);
}

.voyage-list-container{
    width: 100%;
    display: block;
}

.voyage-list{
    width: 100%;
    display: flex;
    align-items: center;
    border-top: 1px dashed #ddd;
    padding: 20px;
}

.voyage-list>.voyage-info{
    width: 100%;
    margin-right: 30px;
}

.voyage-list>.voyage-info>.voyage-info-title{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.voyage-cooperative{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 10px;
}

.voyage-cooperative>img{
    width: 100px;
    height: 100px;
    border: 1px solid #efefef;
    border-radius: 10px;
    margin-right: 10px;
}

.voyage-cooperative>.cooperative-info>h3{
    margin: 0;
    font-size: 22px;    
}

.voyage-cooperative>.cooperative-info>span{
    opacity: 0.5;
    font-size: 18px;
    text-wrap: wrap;
}

.voyage-price{
    font-size: 28px;
    color: #FF8B04;
    font-family: "JotiOne Regular";
    text-wrap: nowrap;
}

.voyage-trajet{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.trajet-container{
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-bottom: 20px;
}

.trajet-designation>h1{
    font-family: "KaushanScript Regular";
    font-size: 30px;
    margin: 0;
}

.trajet-designation>span{
    font-family: "KaushanScript Regular";
    font-size: 18px;
    opacity: 0.5;
}

.trajet-design{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    transform: translateY(-8%);
}

.trajet-design>img{
    width: 40px;
}

.trajet-design>.dash-line{
    width: 60px;
    border: none;
    border-top: 2px dashed #333;
    margin: 0 10px;
}

.voyage-text{
    font-family: "JotiOne Regular";
    color: #888
}


.voyage-important{
    font-family: "JotiOne Regular";
    font-size: 22px;
}

@media only screen and (max-width: 1400px) {
    .voyage-data{
        width: 100%;
    }
    .voyage-client-container{
        display: block;
    }

    .voyage-list-container{
        width: 100%;
    }
    .voyage-filter{
        width: 100%;
    }
}

@media only screen and (max-width: 900px) {
    .voyage-list{
        display: block;
    }

    .voyage-info{
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 600px) {
    .voyage-list>.voyage-info>.voyage-info-title,.voyage-cooperative{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cooperative-info>h3{
        text-align: center;
        padding-top: 10px;
    }
}


@media only screen and (max-width: 450px) {
    .trajet-design>.dash-line{
        width: 30px;
        margin: 0 5px;
    }
}

/**** RESERVATION LEGEND ****/
.reservation-legend{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.reservation-legend-text{
    display: flex;
    align-items: center;
    margin-right: 30px;
}

.reservation-legend-text>div{
    margin-right: 10px;
    width: 15px;
    height: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.reservation-libre{
    background: #F0F0F0;
    border: 1px solid transparent;
}

.reservation-avance{
    border: 1px solid #ff5e1f; 
}

.reservation-paye{
    background: #09ff00;
    border: 1px solid transparent;
}

.reservation-place{
    background: #ff5e1f;
    border: 1px solid transparent;
}

/*** RESERVATION SELECTED ***/
.reservation-info-container{
    background: #f7f7f7;
    padding: 10px 20px;
    margin-bottom: 20px;
}

.reservation-validation-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.reservation-btn-container{
    display: flex;
    align-items: center;
}

.reservation-btn-container>svg{
    margin-left: 10px;
}

/*** RADIO FILTER ***/
.input-radio-group{
    display: block;
    width: 100%;
}

@media only screen and (max-width: 1400px) {
    .input-radio-group{
        width: 100%;
        display: flex;
    }    
}

@media only screen and (max-width: 460px) {
    .input-radio-group{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }    
}