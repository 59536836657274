.badge-card {
    width: 385px;
    height: calc((100vh / 3) - 20px);
    border: 1px solid #333;
    padding: 10px 0;
    margin-top: 5px;
    margin-right: 10px;
    margin-bottom: 5px;
    margin-left: 3px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    color: black;
    border-radius: 10px;
    font-weight: 100;
    font-weight: bold;
}


.container-badge-profil {
    margin: 10px 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
}

@media only screen and (max-width: 900px) {
    .container-badge-profil {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 560px) {
    .container-badge-profil {
        grid-template-columns: repeat(1, 1fr);
    }
}


@media print {
    .container-badge-profil {
        page-break-after: always;
        page-break-inside: avoid;
        break-inside: avoid;
        height: 100%;
        padding: 10px;
        width: auto;
        height: auto;
    }

    .badge-item {
        page-break-inside: avoid;
        break-inside: avoid;
        height: auto;
    }

    @page {
        size: portrait;
    }
}

/*** BADGE ITEM ***/

.badge-item {
    width: 100%;
    height: 357px;
    padding: 8px;
    border: 1px solid #333;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

.badge-item-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
}

.badge-item-header>img {
    width: 120px;
    transform: translateY(-2px);
}

.badge-item-header>h4 {
    text-align: center;
    margin: 0;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}

.badge-item-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}

.badge-item-detail>h4 {
    text-align: center;
    margin: 0;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}

.badge-item-detail>span {
    text-align: center;
    font-weight: 700;
    margin: 0;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    color: #fff;
    background: #ff3300;
    padding: 3px 5px;
    border-radius: 5px;
}

.badge-profile-container {
    width: 100px;
    min-width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 50%;
    padding: 3px;
    background: #f9f9f9;
}

.badge-profile-container>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #fff;
}

.badge-content>span {
    margin-left: 15px;
}

.badge-left-content {
    width: 250px;
    position: relative;
}



.profile-user {
    height: 150px;
    width: 150px;
    border: 5px solid darkgray;
    border-radius: 50% 50%;
    margin-bottom: 5px;
}

.qr-code {
    position: absolute;
    bottom: 10px;
    height: 110px;
    width: 94%;
    display: flex;
    flex-direction: row;
    justify-content: initial;
    color: #fff;
    font-size: 20px;
    background-color: black;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.qr-code>div {
    margin-left: 15px;
    height: 90px;
    margin-top: 10px;
    max-width: 90px;
    width: "100%";
    border: 1px solid;
    padding: 5px;
    background-color: #fff;
    border-radius: 5px;
    width: 100px;
}

.qr-code>span {
    font-size: large;
    color: #fff;
    font-size: large;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}