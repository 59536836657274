
.carseat-container{
    display: flex;
    margin-right: 10px;
    justify-content: center;
}

.carseat {
    width: auto;
    border: 1px solid #e6e6e6;
    padding: 10px;
    border-radius: 10px ;
    background: #f9f9f9;
}

.car-row {
    display: flex;
}

.driver-seat{
    width: calc(100% - 90px);
    height: 25px; 
    background: #fff;
    margin: 5px;
}


.seat {
    width: 30px;
    height: 25px;
    background: #f0f0f0;
    /*border: 1px solid #ccc;*/
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 5px;
    border-radius: 3px;
    font-size: 13px;
    font-weight: 800;
}

.seat-selected{
    background: #FF8B04;
    color: #fff;
    border: 1px solid transparent;
}

.seat-avanced{
    background: #fff;
    color: #FF8B04;
    border: 1px solid #FF8B04;
}

.seat-payed{
    background: #33cb00;
    color: #fff;
    border: 1px solid transparent;
}