.search-bar-container{
    border: 1px solid #f9f9f9;
    padding: 15px 12px;
    border-radius: 5px;
    margin-top: 10px;
    background: #f9f9f9;
}

.search-bar{
    width: 100%;
    display: flex;
    align-items: center;
}

.adress-item{
    font-size: 16px;
}

.list-detail{
    margin-top: 10px;
    width: 100%;
    background: #fff;
    list-style: none;
    padding: 10px 15px;
    border-radius: 5px;
}

.list-detail>li{
    padding: 10px 0;
    border-bottom: 1px solid #fff;
}

.list-detail>li:last-child{
    border-bottom: none;
}

.search-text{
    background: #f9f9f9;
    margin-right: 5px;
}

.search-value {
    display: flex;
    align-items: center;
}

.search-text>div{
    display: flex;
    align-items: center;
}