::-webkit-scrollbar {
    width: 6px;
    height: 5px;
    position: absolute;
}

::-webkit-scrollbar-thumb {
    background: #cacaca;
    border-radius: 5px;
}

/*** TEXT ***/

body {
    /*background: #f9f9f9;*/
}

a {
    text-decoration: none;
    color: inherit;
    font-size: 14px;
}

h1 {
    margin: 30px 0px;
    font-size: 22px;
    line-height: 1.5;
    font-weight: 600;
}

h2 {
    margin: 20px 0px;
    font-size: 20px;
    line-height: 1.5;
    font-weight: 600;
}

h3 {
    margin: 10px 0px;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 600;
}

h4 {
    margin: 7px 0px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
}

h5 {
    margin: 4px 0px;
    font-size: 15px;
    line-height: 1.5;
    font-weight: 600;
}


.capitalize {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}

.underline{
    text-decoration: underline;
}

.center {
    text-align: center;
}

.primary {
    color: #073570;
}

.danger {
    color: #b71c1c;
}

.secondary {
    color: #888;
}

.warning {
    color: #827717;
}

.text-container {
    padding: 0 30px;
}

.orange {
    color: #FF8B04;
    background: #ff8a0483;
}

.text-orange {
    color: #FF8B04;
}

.text-orange-w {
    color: #ff3300;
}

.text {
    color: #666;
    white-space: pre-line;
}

.svg-text {
    margin-right: 5px;
    transform: translateY(2px);
    fill: #dcdcdc;
}

.p-preline{
    text-align: justify;
    white-space: pre-line;
    line-height: 1.5;
}

p {
    margin: 2px 0;
}

/*** END TEXT ***/

/*** GRID ***/

.grid-one {
    display: grid;
    grid-template-columns: repeat(1, 100%);
    grid-row-gap: 2px;
    grid-column-gap: 10px;
}

.grid-two {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 2px;
    grid-column-gap: 10px;
}

.grid-three {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 2px;
    grid-column-gap: 10px;
}

.grid-four {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-row-gap: 2px;
    grid-column-gap: 10px;
}

.grid-five {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-row-gap: 2px;
    grid-column-gap: 10px;
}

@media only screen and (max-width: 1250px) {
    .grid-three {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 2px;
        grid-column-gap: 10px;
    }
}

@media only screen and (max-width: 650px) {

    .grid-three,
    .grid-two {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 2px;
        grid-column-gap: 10px;
    }
}


/*** END GRID ***/

.svg-margin-right {
    margin-right: 10px;
}

.vertical-align {
    width: 100%;
    display: flex;
    align-items: center;
}

.space-between {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 400px) {
    .space-between.contact-and-date {
        flex-direction: column;
    }
}

.space-start-between {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.space-around {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.justify-center {
    width: 100%;
    display: flex;
    justify-content: center;
}

.justify-right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.pointer {
    cursor: pointer;
}