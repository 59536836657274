.blog {
    width: 100%;
}

.blog-view-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
}

.blog-view-header>h1 {
    margin: 0;
}

.blog-view-header>p {
    opacity: 0.5;
    text-align: center;
    margin: 0;
}

.blog-view-header>.input-blog-filter {
    width: 500px;
    min-width: 300px;
    background: #f9f9f9;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 12px 8px;
    border-radius: 5px;
    position: relative;
}

.input-blog-filter>span {
    width: 30px;
    display: flex;
    justify-content: center;
}

.input-blog-filter>span>svg {
    color: #ddd;
    transform: translateY(-1px);
    opacity: 0.5;
}


.input-blog-filter>input {
    width: calc(100% - 50px);
    border: none;
    outline: none;
    padding: 0 8px;
    background: none;
    transform: translateY(1px);
    font-size: 16px;
}

.input-blog-filter>svg {
    position: absolute;
    top: 25%;
    right: 8px;
    cursor: pointer;
}

.separator {
    margin: 0 10px;
}

@media only screen and (max-width: 600px) {
    .blog-view-header>.input-blog-filter {
        width: 100%;
    }
}

.blog-list-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
}

.blog-list-tabs>span {
    margin: 5px;
    text-align: left;
    padding: 8px 15px;
    border-radius: 20px;
    cursor: pointer;
    font-weight: 500;
    background: #f9f9f9;
}

.blog-list-tabs>span:first-child {
    margin-left: 0;
}


.blog-list-tabs>.blog-list-tabs-active {
    background: #ff330030;
    color: #ff7f5f;
}

.blog-view-top4 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blog-top4-first {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 10px;
}

.blog-top4-first>.img-container {
    width: 60%;
    height: 400px;
    padding: 3px;
    background: #f9f9f9;
    min-width: 500px;
    object-fit: contain;
    border-radius: 10px;
}

.blog-top4-first>.img-container>img {
    width: 100%;
    min-width: 500px;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    border: 3px solid #fff;
}

.blog-top4-first>.blog-details {
    width: 35%;
    margin-left: 20px;
    min-width: 400px;
    padding: 0 3px;
}

.blog-top4-first>.blog-details>.categorie {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.blog-top4-first>.blog-details>.categorie>span {
    font-size: 16px;
}

.blog-top4-first>.blog-details>h1 {
    font-size: 35px;
    margin: 10px 0;
    cursor: pointer;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.blog-top4-first>.blog-details>h1:hover {
    text-decoration: underline;
}

.blog-top4-first>.blog-details>p {
    font-size: 16px;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}

.blog-details-footer {
    display: flex;
    align-items: center;
    margin-top: 0px;
}

.blog-details-footer>.img-container {
    width: 50px;
    min-width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 50%;
    padding: 3px;
    background: #f9f9f9;
}

.blog-details-footer>.img-container>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #fff;
}

.blog-details-footer>p {
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 600;
    opacity: 0.8;
}

@media only screen and (max-width: 800px) {
    .blog-top4-first>.blog-details>h1 {
        font-size: 22px;
    }

}

@media only screen and (max-width: 1000px) {
    .blog-top4-first {
        flex-direction: column;
    }

    .blog-top4-first>.img-container {
        width: 100%;
        height: 400px;
        min-width: 100%;
        object-fit: contain;
        border-radius: 10px;
    }

    .blog-top4-first>.img-container>img {
        min-width: 100%;
    }

    .blog-top4-first>.blog-details {
        width: 100%;
        margin-left: 0;
        min-width: 100%;
        margin: 20px 0;
    }
}

/*** BLOG CARD ***/
.blog-list {
    width: 100%;
    background: red;
}

.blog-list-container {
    margin: 10px 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
}

@media only screen and (max-width: 1700px) {
    .blog-list-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 1200px) {
    .blog-list-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 800px) {
    .blog-list-container {
        grid-template-columns: repeat(1, 1fr);
    }
}

.blog-card {
    display: block;
    width: 100%;
}

.blog-card>.img-container {
    width: 100%;
    height: 250px;
    object-fit: contain;
    padding: 3px;
    background: #f9f9f9;
    border-radius: 10px;
}

.blog-card>.img-container>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    border: 3px solid #fff;
}

.blog-card>.card-blog-details {
    margin: 20px 0;
    padding: 0 3px
}

.blog-card>.card-blog-details>.categorie {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.blog-card>.card-blog-details>h1 {
    margin: 10px 0;
    line-height: 1.2;
    cursor: pointer;
}

.blog-card>.card-blog-details>h1:hover {
    text-decoration: underline;
}

.blog-card>.card-blog-details>p {
    font-size: 16px;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}

/*** SHOW BLOG ***/

.show-blog {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.show-blog-img {
    display: block;
    width: 60%;
}

.show-blog-img>.img-container {
    width: 100%;
    height: 400px;
    object-fit: contain;
    padding: 3px;
    background: #f9f9f9;
    border-radius: 10px;
}

.show-blog-img>.img-container>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    border: 3px solid #fff;
}

.show-blog-details {
    display: block;
    width: 38%;
    height: calc(100vh - 90px);
    padding: 0 15px;
    overflow-y: auto;
}

.show-blog-details>.card-blog-details {
    padding: 10px 0;
}

.show-blog-details>.card-blog-details>h1 {
    margin: 10px 0;
    line-height: 1.2;
    cursor: pointer;
}

.show-blog-details>.card-blog-details>p {
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
    white-space: pre-line;
}

@media only screen and (max-width: 1000px) {

    .show-blog {
        flex-direction: column;
    }

    .show-blog-img {
        display: block;
        width: 100%;
    }

    .show-blog-details {
        margin-top: 20px;
        width: 100%;
        padding: 0;
        height: 100%;
        overflow-y: none;
    }

    .show-blog-details>.card-blog-details {
        padding: 0 0 10px 0;
    }
}

/*** COMMENTAIRE LISTE ***/
.show-blog-comment-list {
    width: 100%;
    display: block;
}

/*** COMMENTAIRE CARD ***/
.comment-card {
    display: flex;
    flex-wrap: nowrap;
    margin: 20px 0;
}

.comment-card>.img-container {
    width: 40px;
    min-width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 50%;
    padding: 3px;
    background: #f9f9f9;
}

.comment-card>.img-container>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #fff;
}

.comment-card>.comment-container {
    width: 100%;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}

.comment-card>.comment-container>.comment-text {
    background: #f9f9f9;
    padding: 10px 15px;
    white-space: pre-line;
    color: #333;
    line-height: 1.5;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid transparent;
    display: block;
}

.comment-card>.comment-container>.action-comment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
}

.action-comment>.btn-group>span {
    margin: 0 5px;
    opacity: 0.8;
    cursor: pointer;
}

.comment-container>.action-comment>span:hover {
    text-decoration: underline;
}

.comment-card>.comment-container>.comment-text.comment-active {
    border: 1px solid #ddd;
}