.complete-profil{
    display: block;
    width: 100%;
}


.complete-profil>form{
    background: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 15px;
}

